.program-single-section {
	.s-inner-wrap {
		padding-top: 20px;
	}

	.program-breadcrumbs {
		margin-bottom: 1rem;
		font-size: var(--fluid-body-sm);
		.breadcrumb_last {
			color: $secondary-color;
			font-weight: 600;
		}
	}

	.f-grid {
		display: flex;
		flex-flow: column nowrap;

		@include mq(m) {
			flex-flow: row nowrap;
		}
	}

	.sidebar-wrapper {
		background-color: $off-white;
		width: 100%;
		max-width: 100%;
		padding: 1.2em 2em;
		flex-shrink: 0.1;
		align-self: flex-start;
		margin-bottom: 1.5rem;

		@include mq(m) {
			width: 250px;
			min-height: 300px;
			margin-right: 30px;
		}

		@include mq(l) {
			width: 275px;
		}

		ul {
			margin: 0;
			list-style: none;
			padding-left: 0;
			display: none;

			@include mq(m) {
				display: block;
			}

			li {
				padding-bottom: 0.6em;
			}
		}
	}

	.program-single-content {
		width: 100%;
	}
}

.select-wrap {
	display: flex;
	flex-flow: column nowrap;

	label {
		visibility: hidden;
		display: none;
	}

	select {
		appearance: none;
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.5 7.7'><path d='M6.7 5.4L11.8.3a1 1 0 011.4 0 1 1 0 010 1.3L7.4 7.4a1 1 0 01-1.3 0L.3 1.8A1 1 0 011.6.3z' fill='%23b04ec4'/></svg>")
			no-repeat;
		background-size: 16px;
		background-position: calc(100% - 20px) center;
		background-repeat: no-repeat;
		border: none;
		outline: none;
		background-color: $off-white;
		color: $primary-color;
		font-weight: 600;
		font-size: var(--fluid-body);
	}

	@include mq(m) {
		display: none;
	}
	&.event-type-select-wrap {
		@include mq(m) {
			display: flex;
		}
		@include mq(l) {
			display: none;
		}
	}
}
