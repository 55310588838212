.single-post {
	background-color: #fff;
    position: relative;
    
	.single-post-content {
		display: flex;
		flex-flow: column nowrap;
		width: 65ch;
		max-width: 100%;
		margin: 0 auto;
	}

	.post-top-bar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-size: var(--fluid-body-sm);

		.author-wrap {
			display: flex;
			justify-content: space-between;

			.author-img {
				border: 1px solid $black;
				border-radius: 500px;
				height: 3em;
				width: 3em;
				overflow: hidden;
				margin-right: 1em;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center center;
				}
			}

			.author-name {
				font-weight: 600;
			}
			.post-date {
				color: $gray;
			}
		}
	}

	.post-image {
		display: flex;
		width: 100%;
		margin: 1.5rem 0;
		img {
			width: 100%;
		}
	}
}
