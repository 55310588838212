#news{
	background-color: #fff;
    position: relative;
    padding-top: 60px;
}
.archive-grid {
	display: grid;

	grid-template-columns: 1fr;
	grid-gap: 20px;
	margin-left: 1rem;
	margin-right: 1rem;
	@include mq(m) {
		grid-template-columns: 1fr 1fr;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	@include mq(l) {
		grid-template-columns: 1fr 1fr 1fr;
		margin-left: 4rem;
		margin-right: 4rem;
	}
}

.post-card {
	display: flex;
	flex-flow: column nowrap;
	//box-shadow: 2px 2px 10px rgba($black, .3);

	.post-card-image {
		position: relative;
		padding-bottom: 64%;
		width: 100%;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	.post-card-content {
		display: flex;
		flex-flow: column nowrap;
		//padding: 1rem;
		padding: 8px 0 0 1px;
		flex-grow: 1;

	}

	.post-card-title {
		padding-right: 27px;
	}

	.post-card-date {
		color: $purple;
		font-family: $body-font-name;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		margin-bottom: 3px;
	}

	.post-card-excerpt {
		margin-top: 0;
		font-size: var(--fluid-body-sm);
	}

	.post-card-link {
		display: flex;
		align-items: center;
		color: #8DC73F;
		margin-left: 0;
		font-family: $body-font-family;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		margin-top: 10px;
		.arrow-icon {
			font-size: 21px;
			margin-left: 7px;
			svg {
				height: .8em;
				transition: transform .3s ease, color .3s ease;
				transform: translateX(0);
			}
		}

		&:hover, &:active, &:focus {
			.arrow-icon {
				svg {
					transform: translateX(10px);
					color: $primary-color;
				}
			}
		}

		&:active {
			color: $primary-color;
		}
	}
}
