.services-list {
    background-color: $primary-color;
    padding: 25px 0 27px;
    position: relative;
    z-index: 1;
    
    @include mq(m){
        padding: 55px 0 58px;
    }
    .top{
        h2{
            color: #fff;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 29px;
            @include mq(m){
                font-size: 37px;
                line-height: 46px;
            
            }
        }
    }
    .grid-wrap {
        ul{
            column-count: 1;
            column-gap: 20px;
            row-gap: 20px;
            list-style-type: none;
            padding: 0;
            margin: 0;
            @include mq(m){
                column-count: 2;
                gap: 41px;
            }
            @include mq(l){
                column-count: 3;
            }
            li{
                margin-bottom: 40px;
                width: 100%;
                float: left;
            }
            .program-box{
                display: flex;
                justify-content: start;
                column-gap: 20px;
                color: #fff;
                i.program-icon {
                    width: 100%;
                    height: auto;
                    max-width: 30px;
                    max-height: 30px;
                    
                    svg {
                        height: 100%;
                        width: 100%;
                    }
                }
                &:hover{
                    svg{
                        color: #B14FC5;
                        transition: all .3s ease;
                        g{
                            path{
                                stroke: #B14FC5;
                            }
                        }
                    }
                    div{
                        color: #B14FC5;
                        transition: all .3s ease;
                    }
                }
            }
        }
    }
}

.resource-navigator {
    background-color: $purple;

    .resource-navigator-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7vw;
        flex-direction: column;
        margin: 0 auto;

        @include mq(m) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 1200px;
        }
    }
    
    .left-cell {
        width: 100%;
        max-width: 32em;
        height: auto;

        h3 {
            color: white;
        }
    }

    .right-cell {
        width: 100%;
        max-width: 10em;
        height: auto;
        @include mq(m, down) {
            margin-right: auto;
        }
    }
}

.services-blocks {
    .s-service-cell {
        padding: 64px 0;
        @include mq(l){
            padding: 124px 0;
        }
        .s-inner-wrap{
            overflow: visible;
        }

        .row-service{
            display: grid;
            grid-template-columns: 100%;

            @include mq(m){
                grid-template-columns: 45% 55%;
            }
            .left-cell{
                width: 100%;
                display: flex;
                align-items: end;
                position: relative;
                .icon-right, .icon-left{
                    position: absolute;
                }
                
                .icon-left{
                    width: 320px;
                    bottom: -8%;
                    left: 5%;
                    color: #2E3192;
                    
                    @include mq(m){
                        width: 348px;
                        bottom: -15%;
                    }
                }
                .icon-right{
                    width: 81px;
                    top: 112px;
                    color: #8DC73F;
                    transform: rotate(20deg);	
                }
            }
            .right-cell{
                padding: 34px 30px 42px 20px;

                @include mq(m){
                    padding: 64px 60px 82px 0;
                }
                h2{
                    color: #fff;
                    font-size: 28px;
                    line-height: 36px;
                    margin-bottom: 0;
                    @include mq(m){
                        font-size: 37px;
                        line-height: 46px;
                    }
                }
                p{
                    margin: 25px 0 16px;
                    color: #fff;
                }
            }
        }
    }
    .s-service-cell:nth-child(4n+1){
        .s-inner-wrap{
            background-color: $purple;
            padding: 0;
            
            .left-cell {
                max-width: 500px; 
                display: none;

                .icon-right {
                    right: -4%;
                }

                @include mq(m) {
                    display: inherit;
                }
            }
        }
    }
    .s-service-cell:nth-child(4n+3){
        .s-inner-wrap{
            background-color: #2E3192;
            padding: 0;

            .left-cell {
                padding-left: 0;
                max-width: 517px;
                display: none;

                .icon-right {
                    right: 7%;
                }

                @include mq(m) {
                    display: inherit;
                }
            }
        }
    }

    .f-service-cell {
        position: relative;
        padding: 30px 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @include mq(m){
            padding: 90px 0;
            background-position: center right;
            background-size: cover;
        }
        .overlay{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
            background: linear-gradient(90deg, #fff 45%, #ed7f2800 70%);
            @include mq(s, down){
                background: linear-gradient(90deg, #fff 44%, #ed7f2800 100%);
            }
        }
        
        .left-cell {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            z-index: 1;


            @include mq(m) {
                display: inherit;
            }
        }

        &::after {
            content: "";
            position: absolute;
            width: 10%;
            height: calc(100% + 0px);
            top: 50%;
            transform: translateY(-50%) scale(-1);
            right: 0;
            z-index: 2;

            @include mq(s, down){
                width: 0;
            }
        }

        .s-inner-wrap {
            position: relative;
            overflow: visible;

            .icon-right, .icon-left{
                position: absolute;
                color: #EBEBEB;
            }

            .icon-left{
                width: 208px;
                bottom: -10%;
                left: 0;
                @include mq(l){
                    left: -6%;
                }
            }

            .icon-right {
                width: 81px;
                right: -4%;
                top: -10px;
                transform: rotate(20deg);

                @include mq(l){
                    top: -46px;
                }	
            }
            .right-col {
                position: absolute;
                right: 0;
                top: 0;
                width: 60%;
                height: auto;
                display: none;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    max-height: 300px;
                    object-fit: cover;
                    
                    @include mq(l) {
                        max-height: 500px;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 102%;
                    top: 0;
                    z-index: 1;
                    background: linear-gradient(90deg, #ffffff 20%, #ffffff00, #ffffffff);
                }


            }
            @include mq(s) {
                .left-cell {
                    width: 38%;
                }

                .right-col {
                    display: initial;
                }       
            }
        }

    }
}