/**
 * --- Font-face setters ---
 * Supports Local and external font foundries
 * Prefer using .woff files when using local fonts
 **/


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

// Settings for font-face
$header-font-name: "Poppins";
$body-font-name: "Open Sans";
$body-font-family: "Outfit", sans-serif;
$header-font-family: $header-font-name, -apple-system, "Ubuntu", sans-serif;
$body-font-family: $body-font-name, -apple-system, "Ubuntu", sans-serif;

@mixin general-typography-setter() {
	h1, h2, h3, h4, h5, h6 {
		font-family: $header-font-family;
		font-weight: $header-font-weight;
		line-height: $header-line-height;
		margin-bottom: $header-margin-bottom;
		color: $header-color;
	}

	html {
		font-weight: $body-font-weight;
		font-family: $body-font-family;
		line-height: $body-line-height;
		color: $gray;
	}

	p {
		margin-bottom: $paragraph-margin-bottom;
	}
}

/** --- General typography settings --- **/
$header-line-height: 1.2;
$header-margin-bottom: 0.5rem;
$header-font-weight: bold;
$header-color: $primary-color;

$body-font-weight: 400;
$body-line-height: 1.4;
$body-font-color: $gray;
$paragraph-margin-bottom: 1rem;
