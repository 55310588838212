.pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	li {
		span.current {
			background: $secondary-color;
			color: $white;
			padding: 0.5rem 0.65rem;
			border: none;
			border: 2px solid $secondary-color;
			font-weight: bold;
		}
		a {
			font-weight: bold;
			background: $white;
			color: $primary-color;
			border: 2px solid #c9c9c9;
			padding: 0.5rem 0.65rem;
			@include transition;

			&:hover {
				background: $secondary-color;
				color: $white;
				border: 2px solid $secondary-color;
			}
		}

		&.pagination-next,
		&.pagination-previous {
			a {
				border: none;

				&:hover {
					border: none;
					background: none;
				}

				svg path {
					@include transition;
				}
				&:hover {
					svg path {
						fill: $primary-color;
					}
				}
			}
		}
	}
}
