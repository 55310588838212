/**
 * Better Menu Theme file!
 */

/**
 * Better Menu Theme file!
 */

[data-better-menu] {
	a {
		padding: var(--bm-a-pad-top) var(--bm-a-pad-right) var(--bm-a-pad-bottom) var(--bm-a-pad-left);
		color: var(--bm-a-color);
		transition: var(--bm-a-transition);
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--bm-a-color-hover);
		}
		&:active,
		&[data-bm-active-anchor] {
			color: var(--bm-a-color-active);
		}
	}
}

[data-better-menu="vertical"] {
	ul {
		li {
			ul {
				position: relative;
				height: max-content;
				max-height: 0;
				padding: 0;
				overflow: hidden;
				transition: max-height 0.5s ease, visibility 0.5s ease, padding 0.5s ease, transform 0.5s ease;
				&[data-bm-active] {
					padding: var(--bm-vert-submenu-pad);
					max-height: 300px;
					visibility: visible;
				}
			}
		}
	}
}

// [data-better-menu="horizontal"] {
// 	ul {
// 		li {
// 			ul {
// 				position: absolute;
// 				top: 100%;
// 				height: auto;
// 				width: var(--bm-submenu-width);
// 				transition: transform 0.3s ease, visibility 0.3s ease;
// 				transform-origin: top;
// 				transform: scaleY(0);
// 				background: white;
// 				box-shadow: 2px 2px 10px rgba($black, .3);
// 				z-index: 100;

// 				&[data-bm-active] {
// 					// Fancy animations are borderline impossible with absolute auto height containers. Stick with transform
// 					transform: scaleY(1);
// 					visibility: visible;
// 				}
// 			}
// 		}
// 	}
// }
