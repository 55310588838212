.wpum-form, .post-password-form {
    display: flex;
    flex-direction: column;

    fieldset {
        padding: 0;
    }

    label {
        color: #000;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;
    }

    input {
        border: 1px solid #B14FC5;
        background: #FFF;
        outline: 0;
        padding: 17.5px 23px;
        color: rgba(0, 0, 0, 0.50);
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .button {
        margin-top: 41px;
        background: #8DC73F;
        border: 0;
        color: #FFF;
        font-family: $header-font-name;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        padding: 18px;
        text-transform: capitalize;
        cursor: pointer;
        display: flex;
        width: 100%;
        max-width: 185px;
    }
}

.leftCol {
    max-width: 550px;
    width: 100%;
    
    @include mq(m, down){
        max-width: 100%;
    }

    h1 {
        font-size: 52px;
        line-height: 66px;
    }

    p {
        margin-top: 0;
        color: #000;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
    }

    .wpum-form{
        .fieldset-privacy {
            margin-top: 22px !important;
            padding-bottom: 0;

            .wpum-required {
                display: none;
            }
            .field {
                display: flex;
                justify-content: flex-start;
                gap: 21px;
                align-items: center;
            }

            .input-checkbox{
                width: 45px;
                height: 30px;
                border: 1px solid #B14FC5;
                padding: 0;
            }

            .description{
                color: #000;
                font-family: "Open Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                opacity: 1;
                margin: 0;
                a{
                    text-decoration: underline;
                    color: #000;
                    font-weight: 400;
                }
            }
        }
        
        .fieldset-remember {
            display: none;
        }
    }
}

.outerWrap {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(58% - 14rem);
    height: 100%;
    justify-content: flex-end;
    
    img {
        width: 100%;
        object-fit: cover;
    }

    @include mq(m, down){
        position: relative;
        width: 100%;
    }
}


.wpumForm-register {
    .leftCol {
        margin: 52px 0px;

        .wpum-form {
            fieldset {
                margin-top: 35px;
            }
        }
    }
    .wpum-action-links{
        display: none;
    }
}
.wpumForm-login{
    .leftCol{
        margin: 58px 0px;
        fieldset{
            margin-bottom: 35px;
        }
    }

    .form{
        display: flex;
        flex-direction: column-reverse;
        .wpum-action-links{
            margin-top: 5px;
            li{
                color: #000;
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                a{
                    color: #B14FC5;
                }
            }
        }
    }
}
// Account page
.wpum-account-page{
    margin-bottom: 54px;
    .wpum_one_third{
        width: 100%;
        margin-right: 0;
        #wpum-account-forms-tabs{
            margin-bottom: 63px;
            ul{
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                li{
                    &.tab-view{
                        display: none;
                    }
                    &.active{
                        background: #B14FC5;
                        a{
                            color: #fff;
                        } 
                    }
                    margin: 0;
                    padding: 17px 15px 17px 37px;
                    border-radius: 0;
                    width: 32%;
                    background: #EBEBEB;
                    a{
                        font-family: "Open Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                    @include mq(m, down){
                        width: 100%;
                    }
                }
            }
        }
    }
    .wpum_two_third{
        width: 100%;
        .fieldset-user_cover, .fieldset-user_nickname, .fieldset-user_displayname, .fieldset-user_website, .fieldset-user_description{
            display: none;
        }
        .fieldset-user_email{
            margin: 15px 0 20px;
        }
        .fieldset-user_firstname{
            width: calc(50% - 12px);
            display: inline-block;
            margin-right: 20px;
            @include mq(m, down){
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .fieldset-user_lastname{
            width: calc(49% - 5px);
            display: inline-block;
            @include mq(l, down){
                width: calc(49% - 7px);
            }
            @include mq(m, down){
                width: 100%;
            }
        }
        .button{
            margin-top: 36px;
        }
    }
}

#wpum-submit-password-form{
    .fieldset-password_repeat{
        margin-top: 20px;
    }
}

.page-template-register, .page-template-login{
    main{
        flex-grow: 1;
        overflow: hidden;
        position: relative; 
    }
    #footer{
        margin: 0 !important;
    }
}