.awsm-jobs-single-title{
    margin-bottom: 35px;
}

.awsm-job-form-inner h2{
	font-size: 30px;
}

.awsm-application-submit-btn, .awsm-jobs-alerts-btn {
    background: #8dc63f;
    border: unset;
    border-radius: 0;
    color: #fff;
    font-size: var(--fluid-body-sm);
    font-weight: 700;
    margin: 0;
    padding: 15px 40px;
    transition: background-color .3s ease;
}

.awsm-application-submit-btn:hover{
background-color: #9ecf5c;
}

.awsm-job-specification-label{
    color: #b04ec4;
}

.awsm-job-form {
    display: none;
}