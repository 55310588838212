.accordion-block {
	margin-bottom: 2rem;

	.uagb-faq-questions {
		background: $secondary-color;
		color: white;

		padding: 10px 15px;

		.uagb-question {
			color: white;
			font-weight: 600;

			&:hover, &:active, &:focus {
				color: $off-white !important;
			}
		}
	}

	.uagb-faq-item-active {

		.uagb-faq-questions {
			background: $secondary-color-dark;

			.uagb-question {
				color: white;
			}
		}
	}

	.uagb-faq-content {
		padding: 15px;
	}
}
