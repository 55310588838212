.bulleted-list-block {
	padding: 15px 10px;
	box-shadow: 2px 3px 15px rgba($black, .35);
	background-color: $secondary-color;
	color: white;
	margin-bottom: 2rem;

	ul {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 10px;


		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}
	}

	a {
		color: $primary-color;

		&:hover, &:active, &:focus {
			color: lighten($primary-color, 15%);
		}

		&[data-s-active-anchor] {
			color: lighten($primary-color, 15%);
		}
	}
}
