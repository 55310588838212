.wp-block-image {
	img {
		height: auto !important;
	}
}

.magenta-box {
	border: 3px solid $secondary-color;

	h1, h2, h3, h4, h5, h6 {
		color: $secondary-color;
	}
}

.blue-box {
	border: 3px solid $primary-color;

	h1, h2, h3, h4, h5, h6 {
		color: $primary-color;
	}
}

.green-box {
	border: 3px solid $tertiary-color;

	h1, h2, h3, h4, h5, h6 {
		color: $tertiary-color;
	}
}

.spaced-list {
	li {
		padding-bottom: .4rem;
	}
}
