.events-archive-header {
	background-image: url("../images/events-header.jpg");
}

#events {
	background-color: #fff;
	position: relative;
	padding-top: 60px;
	@include mq(s, down) {
		padding-top: 0;
	}
}

.archive-events {
	.archive-events-container {
		background-position: center center;
		background-size: cover;
		min-height: 400px;
		margin: 1.4rem auto;
		width: $global-width + 200px;
		max-width: 100%;
		.s-inner-wrap {
			width: 100%;
			max-width: initial;
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
			.no-event {
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin-top: 85px;

				.no-event-icon {
					display: flex;
					flex-flow: column nowrap;
					align-items: flex-end;
					flex-basis: 0;

					svg {
						height: 100px;
						width: 100px;

						g {
							stroke: #2e3192;
						}
					}
				}

				.no-event-text {
					display: flex;
					flex-flow: column nowrap;
					flex-grow: 1;
					justify-content: space-evenly;
					flex-basis: 0;
					max-width: 400px;
					margin: 4px 0 0 60px;

					h4 {
						line-height: 2rem;
						margin: 0;
						padding: 0;
					}
				}
			}
			.f-grid {
				display: flex;
				flex-flow: wrap-reverse;
				align-items: flex-start;
				gap: 30px;
				padding: 0 2em;
				@include mq(l) {
					flex-flow: row nowrap;
					padding: 0;
				}
			}
			.posts-wrapper {
				display: flex;
				width: 100%;
				flex-direction: column;
				overflow: hidden;
				& > .row {
					display: flex;
					width: 100%;
					margin-bottom: 1em;
					padding-bottom: 1em;
					padding-left: 1em;
					padding-right: 1em;
					gap: 1em;
					flex-wrap: wrap;

					.col {
						width: 100%;
						margin-bottom: 1em;
						.title-area {
							margin-bottom: 1em;
						}
						@include mq(s) {
							width: 48%;
						}
					}
					.event-posts {
						width: 100%;
						&.scroll-posts {
							position: relative;
							.posts-area {
								position: relative;
								@include mq(m) {
									&:before,
									&:after {
										content: "";
										position: absolute;
										z-index: 1;
										bottom: 0;
										left: 0;
										width: 100%;
										height: 25px;
									}
									&:before {
										top: 0;
										background: linear-gradient(
											to bottom,
											rgba($white, 1) 50%,
											rgba($white, 0) 100%
										);
									}
									&:after {
										bottom: 0;
										background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 50%);
									}
								}
								.no-event {
									margin-top: 2.5em;
									padding: 1em 0;
								}
							}
							.c-grid {
								position: relative;
								height: 300px;
								overflow-y: scroll;
								padding-top: 1em;
								padding-bottom: 1em;
							}
							padding-bottom: 1em;
							border-bottom: 1px solid $off-white;
						}
						.term-title-link {
							* {
								display: flex;
								align-items: center;
								gap: 4px;
							}
							&:hover {
								.text {
									opacity: 0.8;
								}
								.icon {
									text-decoration: none;
								}
							}
							.icon {
								font-size: 0.5em;
							}
						}
					}
				}
			}
		}
	}
}

.sidebar-wrapper {
	background-color: $off-white;
	width: 100%;
	max-width: 100%;
	padding: 1.2em 2em;
	flex-shrink: 0.1;
	align-self: flex-start;
	margin-bottom: 1.5rem;

	@include mq(m) {
		width: 100%;
	}

	@include mq(l) {
		width: 275px;
		min-height: 300px;
		// margin-right: 30px;
	}

	ul {
		margin: 0;
		list-style: none;
		padding-left: 0;
		display: none;
		@include mq(l) {
			display: block;
		}

		li {
			padding-bottom: 0.6em;
		}
	}
}

.c-grid {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	@media all and (max-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media all and (max-width: 500px) {
		grid-template-columns: 1fr;
		justify-items: center;
	}
	&.row-layout {
		grid-template-columns: 1fr;
		.simplebar-content {
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;
		}
		.event-card {
			background: white;
			.image {
				@media all and (max-width: 768px) {
					background-image: none !important;
				}
				.headline-text {
					font-size: 14px;
					height: 100%;
					.date {
						font-size: inherit;
					}
				}
			}
		}
	}
	&.slider-layout {
		position: relative;
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		padding-left: 3em;
		padding-right: 3em;
		padding-bottom: 1em;
		justify-content: center;

		.splide__arrow {
			position: absolute;
			top: 40%;
			color: $primary-color;
			border: none;
			background: none;
			&:hover {
				opacity: 0.8;
			}
			// margin: auto;
			&.splide__arrow--prev {
				left: -0em;
				transform: scaleX(-1);
			}
			&.splide__arrow--next {
				right: -0em;
			}
		}
		.splide__track {
			width: 100%;
			overflow: hidden;
			.splide__list {
				display: flex;
				width: 100%;
				padding-left: 0;
				// gap: 20px;
				// overflow: hidden;
				.event-card {
					width: 300px;
					flex-shrink: 0;
				}
			}
		}
		.splide__pagination {
			display: flex;
			list-style: none;
			gap: 0.25em;
			.splide__pagination__page {
				padding: 0.5em;
				border-radius: 1000px;
				border: none;
				&.is-active {
					background: $primary-color;
				}
			}
		}
		.splide__sr {
			display: none;
		}
	}
}

.event-card {
	position: relative;
	display: flex;
	width: 100%;
	max-width: 350px;
	box-shadow: 3px 3px 10px rgba($black, 0.25);
	flex-direction: column;
	flex-wrap: wrap;

	&.row {
		max-width: initial;
		flex-direction: row;
		flex-wrap: nowrap;
		.image {
			width: 35%;
			height: auto;
			flex-shrink: 0;
			background: none !important;
		}
	}
	// &.slider {

	// }

	@include mq(m) {
		max-width: unset;
	}

	.card-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 1rem;
		// width: 100%;
		flex-grow: 1;

		.card-excerpt {
			font-family: $body-font-family;
			color: $gray;
			line-height: 1.5;
			font-size: 14px;
		}

		.card-link {
			display: flex;
			justify-content: flex-end;
			color: $secondary-color;
			margin-left: auto;
			font-family: $body-font-family;
			font-size: 15px;
			.arrow-icon {
				margin-left: 0.5em;

				svg {
					height: 0.8em;
					transition:
						transform 0.3s ease,
						color 0.3s ease;
					transform: translateX(0);
				}
			}

			&:hover,
			&:active,
			&:focus {
				.arrow-icon {
					svg {
						transform: translateX(10px);
						color: $primary-color;
					}
				}
			}

			&:active {
				color: $primary-color;
			}
		}
	}

	.image {
		height: 250px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		// width: 350px;
		width: 100%;

		@include breakpoint(medium) {
			// height: auto;
		}
		@include breakpoint(large) {
			// height: auto;
		}
		.featured-tag {
			font-size: 12px;
			position: absolute;
			top: 0;
			right: 0;
			color: white;
			padding: 0.5em 1em;
			background: $primary-color;
		}
		.headline-text {
			background: $primary-color;
			color: $white;
			padding: 1rem 1rem 0.5rem;
			font-weight: bold;
			font-family: $header-font-name;
			width: 85%;

			span {
				width: 100%;
				display: block;
				font-size: 16px;
				@include fluid-prop(font-size, 12px, 16px);
				font-weight: normal;
			}
		}
	}
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2em;
	margin-bottom: 2em;
	gap: 1em;
}

#myElement {
	// overflow: hidden;
	max-width: 800px;
	height: 150px;
	// margin: auto;
}

.simplebar-scrollbar::before {
	background-color: $primary-color;
}

[data-simplebar] {
	// lets hide the default browser scrollbars on this
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.events-table {
	width: 100%;
	border-collapse: collapse; // This ensures that the borders of the cells fit snugly together
	border: none;
	border-bottom: 1px solid #f0f0f0;

	th,
	td {
		border: none; // Remove borders
		padding: 10px; // Add some padding for readability
	}

	tr:nth-child(odd):not(.head-row) {
		background-color: white;
	}

	tr:nth-child(even),
	.head-row {
		background-color: #f0f0f0;
	}
}
