.error404 {
	#heroSection {
		background: url("../images/fourohfour-background.jpg") no-repeat top center;
		background-size: cover;
		max-width: $global-width;
		margin: 0 auto 2rem;
		.page-header {
			background-image: none;
			font-family: $body-font-family;

			.page-title {
				min-width: 40%;
			}
		}

		.text {
			margin-left: 3rem;
			@include breakpoint(850px, down) {
				margin-left: 0;
				background: rgba($white, 0.8);
				padding: 2rem;
			}
			h2 {
				font-family: $body-font-family;
			}
			p {
				color: $gray;
				font-family: $body-font-family;
				font-weight: bold;
				max-width: 300px;
			}
		}
		a {
			margin-left: 3rem;
			@include breakpoint(850px, down) {
				margin-left: 0;
			}
			margin-top: 3rem;
			font-family: $body-font-family;
			font-weight: normal;

			&:hover {
				color: $white;
			}
		}
	}
}
