.page-template-password-protected {
	.body-controller {
		min-height: auto;
	}

	.providers-header {
		background-image: url("../images/providers-header.jpg");
	}
	h2 {
		font-family: $body-font-family;
	}
	.header-content {
		font-family: $body-font-family;
		@include fluid-prop(font-size, 12px, 18px);
		color: $gray;
	}

		h3 {
			color: $white;
			font-family: $body-font-family;
			@include fluid-prop(font-size, 20px, 34px);
			margin-bottom: 2rem;
		}
}
