.awsm-filter-wrap {
    form { 
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        
        #job-alerts-signup-text {
            display: none;
            position: relative;
            order: 2;
            width: 100%;
            max-width: 200px;

            @media (min-width: 768px) {
                display: block;

            }
        }
    }

    .awsm-filter-items {
        flex-wrap: wrap;

        @media (min-width: 875px) {
            flex-wrap: nowrap;
        }
    }
}

.awsm-filter-wrap.awsm-no-search-filter-wrap.awsm-jobs-alerts-on .awsm-filter-items {
    order: 1;
    width: 60%;
}