.services-uniq {
	@include mq(s, down){
		padding: 15px 0 60px;
	}
}

.general-page-single {
	padding-top: 20px;
    background-color: #fff;
    z-index: 1;
    position: relative;
	@include mq(m){
		padding-top: 60px;
	}
}

.page-header {
	display: flex;
	align-items: flex-end;
	background-position: center center;
	background-size: cover;
	background-image: url("../images/news-default-header.jpg");
	min-height: 200px;
	margin: 0 auto;
	width: $global-width + 200px;
	max-width: 100%;

	.page-title {
		background-color: $secondary-color;
		color: white;
		padding: .5em 1em;
		min-width: 50%;
		max-width: 75%;
		margin-bottom: 0;

		@include mq(m) {
			padding-left: 2em;
			min-width: 30%;
			max-width: 60%;
		}

	}
}

.page-header-hero{
    h2{
        font-style: normal;
        font-weight: 700;
    }
    p{
        color: #000;
        font-family: "Open Sans";
        font-size: 18px;
		line-height: 26px;
        font-style: normal;
        font-weight: 400;
    }
}
.page-header-hero{
    position: relative;
    padding: 15px 0 0;
	background-color: #EBEBEB;

	@include mq(l){
		padding: 24px 0 37px;
	}

	&.inner{
		padding: 15px 0 0 !important;
		@include mq(m){
			padding: 76px 0 47px !important;
		}
		@include mq(s, down){
			padding-bottom: 40px !important;
		}
		.f-grid{
			padding-right: 10px;
		}
		.info-cell{
			@include mq(l){
				min-height: 129px;
			}
		}
		.cell-right{
			@include mq(s, down){
				width: calc(100% + 50px);
				transform: rotate(350deg);
        		top: -50px !important;
			}
			img {
				@include mq(s, down) {
					height: 100%;
				}
			}
		}
	}

    .f-grid {
        display: flex;

		@include mq(l) {
            max-width: 50%;
        }

        @include mq(769px) {
            max-width: 45%;
        }

        .info-cell {
            h2 {
                font-size: 38px;
    			line-height: 46px;

				 @include mq(xl) {
					font-size: 52px;
                	line-height: 66px;
				}

				@include mq(850px, down) {
					font-size: 28px;
                	line-height: 36px;
				}
				@include mq(m, down){
					font-size: 38px;
                	line-height: 46px;
				}
            }    
            p {
                margin-top: 11px;
                margin-bottom: 0;
            }
        }
    }
	
	.col-right-outer {
		display: flex;
		position: relative;
		justify-content: flex-end;

		@include mq(m, down) {
			padding-bottom: 60px;
		}

		@include mq(567px, down){
			padding-bottom: 40px;
			z-index: 1;
		}
		
		@include mq(s, down){
			padding-bottom: 0;
		}

		&::before{
			content: '';
			background-image: url('../images/banner-moon.svg');
			width: 100%;
			height: 350px;
			background-position: 100% 100%;
			background-repeat: no-repeat;
			background-size: contain;
			margin-top: -40px;

			@include mq(m){
				height: calc(100% - 10px);
				width: 98%;
			}

			@include mq(m, down){
				height: 450px;
				width: 100%;
				transform: rotate(6deg);
				left: 0;
				position: relative;
				top: 15px;
				margin-top: 0;
				background-size: cover;
			}

			@include mq(567px, down){
				height: 370px;
				left: 0;
				top: 20px;
				transform: rotate(4deg);
				background-size: cover;
			}
			@include mq(s, down){
				transform: rotate(9deg);
				left: -10px;
        		height: 300px;
				top: -45px;
				background-size: contain;
			}
		}

		@include mq(769px){
			position: absolute;
			right: 0;
			max-width: 55%;
			width: 100%;
			top: 0;
			height: calc(100% + 50px);
		}

		.cell-right{
			z-index: 2;
			position: absolute;
			top: 0;
			@include mq(xl){
				top: -150px;
				height: calc(100% + 40%);
			}
			@include mq(l){
				top: -60px;
				height: calc(100% + 20%);
			}
			@include mq(m, down){
				width: calc(100% + 50px);
				transform: rotate(350deg);
				top: -80px;	
			}
			@include mq(s, down){
				top: -80px;	
			}

			img {
				width: 100%;
				height: 100%;
    			object-fit: contain;
			}
		}
		.moon-icon{
			position: relative;
			right: 0;
			top: -10%;
			height: 110%;
			z-index: 1;
			max-width: 55%;
			width: 100%;
			svg{
				height: 100%;
				width: 100%;
			}
			@include mq(l){
				position: absolute;
				max-width: 96%;
			}
			@include mq(xl){
				max-width: 82%;
			}
		}
	}
}

.videoResources{
	padding: 75px 0 75px !important;
	@include mq(m, down){
		padding: 25px 0 30px !important;
	}
}