#chatbotIconContainer {
    position: fixed;
    right: 2%;
    bottom: 2%;
    z-index: 999;

    #chatTextPopup {
        visibility: visible;
        margin-right: .7em;
        position: relative;
        opacity: 1;
        transform: scale(1);
        transition: 
            visibility 550ms,
            opacity 450ms ease-in-out,
            transform 600ms ease-in-out;

        &.invisible {
            visibility: hidden;
            opacity: 0;
            transform: scale(.5);
        }

        a {
            &:active, &:focus, &:hover {
                .chat-inner-text {
                    color: $secondary-color;
                }
            }
        }
    }



    .icon-text {
        background-color: $white;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px 2px 15px rgba(0 0 0 / 35%);
        padding: 1em;
        opacity: 100;
        width: 100%;
        height: auto;
        max-width: 275px;
        
        .chat-logo-container {
            margin-top: -2em;
            margin-bottom: .75em;
            background-color: white;
            padding: .25em;
            border-radius: 30px;
            
            img {
                width: 100%;
                max-width: 40px;
                height: auto;
            }
        }

        .chat-inner-text {
            color: $primary-color;
            font-weight: 700;
            font-size: 1em;
            text-align: center;
        }	
    }
    
    #closeIcon {
        position: absolute;
        right: 3%;
        top: 8%;
        width: 100%;
        height: auto;
        max-width: 13px;
        cursor: pointer;
    }

    #floatChatIcon {
        background-color: $tertiary-color;
        padding: 12px;
        border: 2px solid $tertiary-color;
        border-radius: 33px;
        position: relative;
        top: 10px;
        left: 75%;
        width: 55px;
        height: 55px;
        box-shadow: 1px 2px 15px rgba(0 0 0 / 35%);
        transition: all 300ms ease-in-out;
        cursor: pointer;

        img {
            margin: 4px auto;
            width: 100%;
            height: auto;
            max-width: 36px;
        }

        &:active, &:focus, &:hover {
            background-color: rgba(84, 84, 84, .2);

            #iconText {
                opacity: 10;
                width: 10%;
            }
        }
    }
}