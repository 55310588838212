#footer {
	background-color: $off-white;

	.s-inner-wrap {
		padding-top: 25px;
		padding-bottom: 25px;
		width: 100%;

		@include mq(m) {
			width: $global-width;
		}
	}
	.f-grid {
		display: flex;
		flex-flow: column nowrap;
		align-content: center;
		.cell {
			padding: 10px 0;
		}
		@include mq(m) {
			flex-flow: row nowrap;
		}
	}
	.logo-cell {
		img {
			width: 100px;
		}
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		display: flex;

		@include mq(l) {
			margin-left: unset;

			img {
				width: 115px;
			}
		}
	}

	.menu-cell {
		.menu {
			padding-left: 0;
			list-style: none;
			display: flex;
			flex-direction: column;
			margin-top: 0;
			gap: 11px;
			margin-bottom: 0;
			// li group
			> li {
				display: flex;
				flex-flow: column nowrap;
				padding: 0 20px;
				a {
					font-family: "Open Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 24px;
				}

				// children of group
				ul {
					list-style: none;
					padding: 0;
					li {
						padding: 0;
						a {
							font-weight: 700;
							font-size: 12px;
							font-family: "Open Sans";
							color: $purple;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
	.cell-mid{
		display: grid;
    	grid-template-columns: 1fr 1fr;
	}
	.links-cell {
		.menu {
			list-style: none;
			margin-top: 0;
			margin-bottom: 0;
			li {
				a {
					font-size: var(--fluid-body-sm);
					font-weight: 700;
					padding-bottom: 7px;
    				display: flex;
				}
				.sub-menu{
					padding-left: 0;
    				list-style-type: none;
					column-count: 2;
					li{
						a {
							font-weight: 700;
							font-size: 12px;
							font-family: "Open Sans";
							color: $purple;
							line-height: 20px;
						}
					}
				}
			}
		}
	}

	.footer-copyright-row {
		background-color: $primary-color;
		padding: 0;
		
		@include mq(m) {
			padding: 14px 0;
		}
		.s-inner-wrap{
			@include mq(l){
				padding: 0;
			}
			.wrap{
				display: flex;
				justify-content: space-between;
				align-content: center;
				align-items: center;
				flex-direction: column;
				@include mq(l){
					flex-direction: row;
				}
			}
		}

		.copyright-text,
		a {
			@extend .fluid-body-sm;
			color: white;
			font-family: $body-font-name;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			@include mq(m){
				font-size: 18px;
			}
		}

		.copyright-text {
			font-weight: 300;
			text-align: center;
		}

		.policy-link {
			padding: 0;
			text-align: center;
			@include mq(m){
				padding: 5px 10px;
			}
			a {
				&:hover,
				&:focus {
					color: $tertiary-color-light;
				}

				&:active {
					color: $tertiary-color;
				}

				&[data-s-active-anchor] {
					color: $tertiary-color;
				}
			}
		}

		.policy-links {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-around;
			list-style: none;
			max-width: 100%;
			padding-left: 0;
			align-self: center;
			margin: 0;

			@include mq(m) {
				flex-flow: row wrap;
				width: 800px;
			}
		}
		.footer-social{
			margin-bottom: 10px;
			@include mq(m){
				margin-bottom: 0;
			}
			ul{
				display: flex;
				list-style-type: none;
				gap: 20px;
				align-items: center;
				margin: 0;
				padding-left: 0;
				li{
					a{
						&:hover{
							svg{
								path{
									fill: #B14FC5;
									transition: all .3s ease;
								}
							}
						}
					}
				}
			}
		}
	}

	#menu-footer-bold-links {
		padding-left: 20px;
	}
}


#footer {
	.menu {
		.footer-title {
		> a {
			margin-bottom: 8px;
		}
		}
	}
}