.location-single {
	background-color: #fff;
	position: relative;
    padding-top: 60px;
	.c-grid {
		display: grid;
		flex-flow: column nowrap;
		grid-template-columns: 1fr;
		grid-gap: 30px;
		width: max-content;
		margin: 0 auto;
		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}

		.cell {
			max-width: max-content;
			width: 100%;
		}
	}

	.image-cell {
		max-width: 100%;
		img {
			width: 400px;
			max-width: 100%;

		}
	}

	.inner-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		max-width: 100%;
		@include mq(m) {
			width: 450px;
			grid-template-columns: 1fr 1fr;
		}

		.hours-cell {
			@include mq(m) {
				grid-column: span 2;
			}
		}
	}
	.service-list {
		 ul {
			 padding-left: 0;
			 margin-top: .5rem;
			 list-style: none;
		 }
	 }

	.cell-title {
		display: flex;
		align-items: center;
		.icon {
			margin-right: .4em;
			display: flex;
			align-items: center;
			svg {
				height: 1.5em;
				color: $secondary-color;
			}
		}
	}

	.directions-wrap {
		margin-top: 2rem;

		.fancy-btn {
			display: inline-flex;
			align-items: center;

			.icon {
				svg {
					height: 1.2em;
					margin-right: .5em;
					color: $secondary-color;
				}
			}
		}
	}
}


