.content-block {
	padding: 30px 15px;
	margin-bottom: 2rem;
	box-shadow: 2px 3px 15px rgba($black, .35);
	.content-block-title {
		margin-bottom: 1rem;
		@include mq(m) {
			margin-bottom: 0;
		}
	}
	@include mq(m) {
		padding: 30px 40px;
	}

	@include mq(l) {
		padding: 30px 50px;
	}

	.wp-block-column {
		display: flex;
		flex-flow: column nowrap;
	}

	.content-block-image {
		margin-top: auto;
		margin-bottom: auto;

		img {
			height: auto !important;
		}
	}

	img {
		height: auto !important;
	}
}
