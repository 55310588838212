$white: #ffffff;
$black: #000000;
$gray: #414042;
$light-gray: #707070;
$off-white: #EBEBEB;
$purple: #B14FC5;

$primary-color: #2E368F;
$secondary-color: #B04EC4;
$tertiary-color: #8DC63F;
$quaternary-color: $gray;
