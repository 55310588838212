.icon-box-block {
	position: relative;
	margin-top: 2rem;
	border: 2px solid $secondary-color;
	padding: 40px 20px 20px;
	height: calc(100% - 2rem);
	
	.icon-box-icon {
		height: 2em;
		margin-top: -1em;
		background: white;
	}

	&:before {
		content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.3 60.5"><path data-name="Icon awesome-heart" d="M57.7 5.2A16.6 16.6 0 0035 7l-2.4 2.5-2.4-2.5c-6-6.3-16-7.3-22.7-1.7a17.5 17.5 0 00-1.2 25.3L30 54.8a3.8 3.8 0 005.5 0l23.5-24.3a17.4 17.4 0 00-1.2-25.3z" fill="none" stroke="rgb(176, 78, 196)" stroke-width="2" vector-effect="non-scaling-stroke"/></svg>');
		position: absolute;
		top: -1.6em;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
		padding: 5px;
		height: 4em;
		width: 4em;
		background-color: white;
	}

	&.icon-heart {
		&:before {
			content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.3 60.5"><path data-name="Icon awesome-heart" d="M57.7 5.2A16.6 16.6 0 0035 7l-2.4 2.5-2.4-2.5c-6-6.3-16-7.3-22.7-1.7a17.5 17.5 0 00-1.2 25.3L30 54.8a3.8 3.8 0 005.5 0l23.5-24.3a17.4 17.4 0 00-1.2-25.3z" fill="none" stroke="rgb(176, 78, 196)" stroke-width="2" vector-effect="non-scaling-stroke"/></svg>');
		}
	}

	&.icon-megaphone {
		&:before {
			content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.9 68.8"> <path d="M30.6 68.8a4.8 4.8 0 01-4.2-2.4l-12-20.8h-2.8A11.6 11.6 0 010 34v-4.8a11.6 11.6 0 0111.6-11.6h32.2a1.4 1.4 0 110 2.8H11.6a8.8 8.8 0 00-8.8 8.8V34a8.8 8.8 0 008.8 8.8h3.6a1.4 1.4 0 011.2.7L28.8 65a2 2 0 002.8.7l6.2-3.6a2 2 0 00.8-2.8L30.2 45a1.4 1.4 0 010-1.4 1.4 1.4 0 011.2-.7h12.4a1.4 1.4 0 110 2.8h-10L41 57.9a4.8 4.8 0 01-1.8 6.6L33 68.1a4.8 4.8 0 01-2.4.7z" fill="rgb(176, 78, 196)"/> <path d="M64.4 40.6a1.4 1.4 0 110-2.8c2.5 0 4.7-1.5 4.7-3.4v-5.6c0-1.9-2.2-3.4-4.7-3.4a1.4 1.4 0 110-2.8c4.1 0 7.5 2.8 7.5 6.2v5.6c0 3.4-3.4 6.2-7.5 6.2z" fill="rgb(176, 78, 196)"/> <path d="M64.4 62.2a1.5 1.5 0 01-.8-.3L43 48.4a1.4 1.4 0 01-.6-1.2V15.7a1.4 1.4 0 01.6-1.1L63.6.2a1.4 1.4 0 011.4 0 1.4 1.4 0 01.8 1.2v59.4a1.4 1.4 0 01-1.4 1.4zM45.2 46.5L63 58.2V4L45.2 16.4z" fill="rgb(176, 78, 196)"/></svg>');
		}
	}

	&.icon-moon {
		&:before {
			content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.5 89"> <g><path data-name="Path 34" d="M6 28.3c23.7-2 38.5 2.8 48.5 46.3C65.4 58.3 71 41.8 59.8 25.4 44.5 3 13 10.7 5.9 28.3z" fill="none" stroke="rgb(176, 78, 196)" stroke-width="2px" vector-effect="non-scaling-stroke"/></g></svg>');
		}
	}

	&.icon-health {
		&:before {
			content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.7 16.7"><path d="M15.7 5.9a.3.3 0 00-.3-.3h-4a.3.3 0 01-.3-.3v-4a.3.3 0 00-.3-.3H6a.3.3 0 00-.3.3v4a.3.3 0 01-.3.3h-4a.3.3 0 00-.3.3v4.9a.3.3 0 00.3.3h4a.3.3 0 01.3.3v4a.3.3 0 00.3.3h4.9a.3.3 0 00.3-.3v-4a.3.3 0 01.3-.3h4a.3.3 0 00.3-.3z" fill="none" stroke="rgb(176, 78, 196)" stroke-width="1"/></svg>');
		}
	}
}
