.body-controller {
	overflow-x: hidden;
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}

body {
	margin: 0;
	overflow-x: hidden;
	&:not([data-s-loaded]) {
		* {
			transition: none !important;
		}
	}
}

// Make sure all columns are flex for easy 100% height divs
.uagb-column__inner-wrap {
	display: flex;
	flex-flow: column nowrap;
}

hr {
	width: 100%;
	&.title-underline {
		border-color: $off-white;
	}
}
