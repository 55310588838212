#location-categories {
	#wpsl-search-wrap {
		display: none;
	}

	.location-title {
		span {
			font-weight: 500;
			color: $secondary-color;
		}
	}
}
