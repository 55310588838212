.video-resources {
    background-color: #fff;
    position: relative;
    padding: 35px 0px;

    .language-select {
        margin-bottom: 2em;

        .language-select-inner {
            .language-select-form {
                select {
                    background-color: $purple;
                    color: white;
                    padding: 15px 12px;
                    font-size: 1em;
                    text-transform: capitalize;
                    border: 2px solid $purple;
                }
            }
        }
    }

    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
        margin: .8em auto 3.5em;

        @include mq(m, down){
            grid-template-columns: 1fr 1fr;
        }
        @include mq(s, down){
            grid-template-columns: 1fr;
        }

        .video-card {

            .thumbnail {
                position: relative;
                cursor: pointer;
                
                img {
                    width: 100%;
                }

                .overlay {
                    content: '';
                    background-color: #b14fc547;
                    align-items: center;
                    bottom: 0;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    margin: auto;
                    opacity: 1;
                    padding-top: 10%;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;
                    transition: .25s;
                    width: 100%;
                }
                &:hover{
                    .overlay{
                        opacity: 1;
                        padding: 0;
                    }
                }
            }

            h3 {
                margin-top: 9px;
                margin-bottom: 0;
                font-size: 1.2em;
                line-height: 1.2em;
            }
            
            .duration {
                color: $purple;
                font-family: $header-font-name;
                font-size: .8em;
                font-style: normal;
                font-weight: 700;
            }

            p {
                margin-top: 9px;
                color: #000;
                font-family: $body-font-name;
                font-size: 1em;
                font-style: normal;
                font-weight: 400;
                line-height: 1em;
            }
        }
        
        .modal {
            position: fixed;
            z-index: 10000; /* 1 */
            top: 0;
            left: 0;
            visibility: hidden;
            width: 100%;
            height: 100%;
        } 
        .modal.is-visible {
            visibility: visible;
        }  
        .modal-overlay {
          position: fixed;
          z-index: 10;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: hsla(0, 0%, 0%, 0.5);
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 0.3s, opacity 0.3s;
        }     
        .modal.is-visible .modal-overlay {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s;
        }    
        .modal-wrapper {
          position: absolute;
          z-index: 9999;
          top: 10%;
          left: 0; 
          right: 0; 
          width: 92%;
          max-width: 1280px;
          margin: 1em auto;
          background-color: #fff;
          box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
        }    
        .modal-transition {
          transition: all 0.3s 0.12s;
          transform: translateY(-10%);
          opacity: 0;
        }   
        .modal.is-visible .modal-transition {
          transform: translateY(0);
          opacity: 1;
        }
        
        .modal-content {
            padding: 5px;

            .vimeo-embed-container {
                overflow: hidden;
                position: relative;
                transform-origin: center;
                width: 100%;
                padding-top: 56.25%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }

                .play-overlay {
                    align-items: center;
                    background: rgba(0, 0, 0, .25);
                    cursor: pointer;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: 0;
                    transition: 1s;
                    width: 100%;
                    z-index: 1;

                    &.playing{
                        opacity: 0;
                        z-index: -1;
                    }
                }
            }
        }

        .modal-header {
          position: relative;
          background-color: #fff;
          padding: 1px;
       }    

        .modal-close {
            position: absolute;
            top: -22px;
            right: -20px;
            padding: 5px;
            color: #aaa;
            background: #eee;
            border-radius: 50px;
            border: 0;
            cursor: pointer;
        }
             
        .modal-close:hover {
          color: #777;
        }
    }
}