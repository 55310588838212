#wpsl-result-list {
	#wpsl-stores {
		height: auto !important;
		ul {
			display: grid;
			grid-template-columns: 1fr;
			gap: 19px;

			@include mq(m) {
				grid-template-columns: 1fr 1fr;
			}

			@include mq(l) {
				grid-template-columns: 1fr 1fr;
			}
		}

		[data-store-id] {
			border-bottom: unset;
			padding: 0 !important;
			display: flex;
			flex-flow: column nowrap;
			 
			.wpsl-store-location {
				display: grid;
				grid-template-columns: 2fr 1fr;
				min-height: 200px;
				border: 1px solid #2E3192;
				transition: all .5s ease;

				@include mq(m) {
					min-height: 270px;
				}
			}
			.service-outer {
				&:hover{
					.wpsl-street{
						color: #fff !important;
					}
					.wpsl-store-location{
						background-color: #B14FC5;
    					border: 1px solid #B14FC5;
					}
					.title, .services-tag, .service-list{
						color: #fff;
					}
					.wpsl-direction-wrap, .location-link{
						color: #fff;
					}
				}
			}

			.store-image-wrap {
				position: relative;
				height: 100%;
				width: 100%;
				order: 1;

				img {
					position: absolute;
					top: 0;
					left: 0;
					max-width: 200px;
					max-height: 300px;
					margin: 0;
					padding: 0;
					border-radius: 0;
					object-position: center center;
					object-fit: cover;
				}
				
				strong {
					position: absolute;
					z-index: 10;
					bottom: 0;
					left: 0;
					width: 90%;
					padding: 10px;
					color: white;
					background: $primary-color;

					a {
						color: white;
					}
				}
			}

			.location-card-info-wrap {
				display: flex;
				flex-flow: column nowrap;
				padding: 30px 20px 25px;
				flex-grow: 1;
				.title{
					color: #2E3192;
					font-family: $header-font-name;
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					line-height: 26px;
				}
				.wpsl-street-address{
					display: flex;
					column-gap: 4px;
					flex-wrap: wrap;
					flex-direction: row;
					.wpsl-street{
						color: #B04EC4;
						font-family: "Open Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
				}
				.left-bottom{
					column-count: 2;
				}
			}
			.services-tag {
				margin-top: 12px;
				color: #000;
				font-family: $header-font-name;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.service-list {
				margin-bottom: 17px;
				color: #000;
				font-family: "Open Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				column-count: 2;
				span{
					display: flex;
				}
			}
			.wpsl-direction-wrap {
				margin-top: auto;
				color: #000;
				font-family: "Open Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
			.location-link {
				display: flex;
				align-items: center;
				color: #8DC73F;
				font-family: $body-font-family;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				.arrow-icon {
					margin-left: .5em;
					svg {
						height: .9em;
						transition: transform .3s ease, color .3s ease;
						transform: translateX(0);
					}
				}
				&:hover, &:active, &:focus {
					.arrow-icon {
						svg {
							transform: translateX(5px);
							color: $primary-color;
						}
					}
				}
				&:active {
					color: $primary-color;
				}
			}
		}
	}
}

.location-results-title {
	margin-top: 2rem;
	display: none;
}

#wpsl-search-wrap {
	background: unset;
	padding-bottom: 0;

	@include mq(l){
		padding-top: 57px;
	}
	div {
		margin-right: 0px;
	}

	form {
		background: unset;

		.wpsl-input {
			display: flex;
			flex-flow: column;
			margin: 0;
			> div{
				margin-right: 0;
			}
		}

		label {
			display: flex;
			color: #000;
			font-family: "Open Sans";
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 26px;
			width: 100%;
			margin-bottom: 19px;
		}
		.input-search-wrap {
			display: flex;
			width: 100%;
			max-width: 100%;
			position: relative;
			margin-right: 0;	
			#wpsl-search-input {
				border: 1px solid #B14FC5;
				width: 100%;
				border-radius: 0;
				flex-grow: 1;
				font-size: 12px;
				padding: 21px 15px;
				border-left: 0;
				outline: 0;
				display: block;
				@include mq(m){
					font-size: 18px;
				}
			}
			.wpsl-search-btn-wrap {
				margin-top: 0;
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 50px;
				margin-right: 0;
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 24px;
					height: 24px;
					background-image: url('../images/search-icon.svg');
					background-size: contain;
					background-repeat: no-repeat;
					transition: filter 0.3s ease;
					cursor: pointer;
					pointer-events: none;
				}
		
				&:hover::after {
					filter: brightness(2.3); 
				}
			}
			#wpsl-search-btn {
				font-size: var(--fluid-body-sm);
				padding: 15px 20px;
				margin: 0;
				border: unset;
				border-radius: 0;
				background: transparent;
				transition: background-color 0.3s ease;
				color: white;
				font-weight: bold;
				height: 100%;
				box-shadow: none;
				opacity: 0;
				width: 100%;
			}

		}
	}

	.loc-top{
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
		margin-right: 0;
		width: 100%;
		.loc-main-title{
			max-width: 85%;
			margin-right: 0;
			@include mq(l){
				max-width: 75%;
			}
			h2{
				font-size: 38px;
				line-height: 46px;
				font-style: normal;
				font-weight: 700;
				@include mq(m){
					font-size: 52px;
					line-height: 60px;
				}
			}
		}
		@include mq(l){
			grid-template-columns: 1fr 1fr;
		}
		.input-search-wrap-outer{
			display: flex;
			max-width: 100%;
			margin-right: 0;
		}
		#wpsl-category{
			margin-right: 0;
			margin-bottom: 0;
			z-index: 2;
			label{
				display: none;
			}
			.wpsl-dropdown {
				background-color: #B14FC5;
				border: 1px solid #B14FC5;
    			border-radius: 0;
				width: 150px !important;

				@include mq(m){
					width: 200px !important;
				}
				.wpsl-selected-item {
					color: #fff;
					font-family: "Open Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					padding: 19.5px 21px !important;
					@include mq(m){
						font-size: 18px;
					}
					&::after{
						border: 0;
						background-image: url('../images/dropdown-arrow.svg');
						background-repeat: no-repeat;
						width: 20px;
						height: 10px;
						transition: all .3s ease;
						margin-top: -4px;					
					}
				}
				&.wpsl-active{
					.wpsl-selected-item {
						&::after{
							transform: rotate(180deg)					
						}
					}
				}
				ul{
					li{
						display: flex;
    					align-items: center;
					}
				}
			}
			#wpsl-category-list{
				color: #fff;
				font-family: "Open Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				padding: 19.5px 10px !important;
				outline: 0;
				@include mq(m){
					font-size: 18px;
					padding: 19.5px 21px !important;
				}
			}
		}
	}
}
.loadlocations{
	display: flex;
	justify-content: center;
	margin-top: 30px;
	.fancy-btn.hidden{
		opacity: 0;
	}
	a.disabled {
		pointer-events: none;
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.locations-header {
	background-image: url("../images/locations-header.jpg") !important;
}

// Let the county card overflow
#wpsl-wrap {
	overflow: visible;
	.wpsl-search {
		background: unset;
		margin-bottom: 42px;
		padding: 0;
	}
	#wpsl-result-list {
		margin: 42px 0 0 0;
	}
}