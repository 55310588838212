/**
 * Buttons
 **/

.fancy-btn {
	position: relative;
	display: inline-flex;
	align-self: stretch;
	text-align: center;
	justify-content: center;
	background: unset;
	z-index: 1;
	border-radius: 0;

	@include mq(m) {
		align-self: flex-start;
	}
	margin: .5rem 0;
	padding: .8em 1.8em;
	font-weight: bold;
	text-decoration: none;
	color: white;
	transition: color .3s ease, background .3s ease;

	// hover states
	&:hover, &:focus, &:active {
		color: $tertiary-color;

		&:before {
			height: 4px;
		}
	}

	// active state - click
	&:active {
		color: $tertiary-color-dark;
		background: rgba($tertiary-color, .1);
	}

	&:before, &:after {
		content: '';
		position: absolute;
		background-color: $tertiary-color;
		z-index: -1;
		transition: height .3s ease, background-color .3s ease;
	}

	&:before {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:after {
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4px;
	}
}


.btn-blue {
	&:before, &:after {
		background-color: $primary-color;
	}
	// hover states
	&:hover, &:focus, &:active {
		color: $primary-color;

	}

	// active state - click
	&:active {
		color: $primary-color-dark;
		background: rgba($primary-color, .2);
	}
}


.heart-btn {
	.heart-icon {
		height: 1em;
		width: 1em;
		margin-right: .4em;
	}
}
.purple-btn {
	position: relative !important;
	display: inline-flex;
	align-self: stretch;
	text-align: center;
	align-items: center;
	justify-content: center;
	background: unset;
	z-index: 1;
	border-radius: 0;

	@include mq(m) {
		align-self: center;
	}
	text-decoration: none;
	color: white;
	transition: color .3s ease, background .3s ease;

	// hover states
	&:hover, &:focus, &:active {
		color: $purple;

		&:before {
			height: 4px;
		}
	}

	// active state - click
	&:active {
		color: $purple;
		background: rgba($purple, .1);
	}

	&:before, &:after {
		content: '';
		position: absolute;
		background-color: $purple;
		z-index: -1;
		transition: height .3s ease, background-color .3s ease;
	}

	&:before {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:after {
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4px;
	}
}

.wp-block-button {
	.wp-block-button__link {
		border-radius: 0;
		@extend .fluid-body;
		@extend .fancy-btn;
	}
}

.fluentform {
	form {
		.ff-btn {
			border-radius: 0;
			background-color: unset !important;
			border: unset !important;
			@extend .fancy-btn;
		}
	}
}


