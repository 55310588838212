/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/

a {
	color: $primary-color;
	transition: color .3s ease;
	font-weight: 600;
	text-decoration: none;

	&:hover, &:focus {
		color: lighten($secondary-color, 15%);
	}

	&:active {
		color: $secondary-color;
	}


	&[data-s-active-anchor] {
		color: $secondary-color;
	}
}

[data-better-menu] {
	[data-s-active-anchor] {
		color: $secondary-color;
	}
}
