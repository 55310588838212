#get-involved {
	background: $secondary-color;
	color: white;
	background-size: 100% 40%;
    background-repeat: no-repeat;
    background-position: bottom;

	@include mq(l){
		background-size: 48% 100%;
		background-position: top right;
	}

	.s-inner-wrap {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.get-involved-title {
		color: white;
	}

	.f-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-content: center;
		align-items: center;
	}

	.info-cell {
		max-width: 100%;
		width: 50%;

		@include mq(m, down){
			width: 100%;
		}

		.inner-wrap {
			max-width: 600px;
			width: 100%;
			margin-left: auto;
			padding-right: 30px;
			padding-left: 20px;

			@include mq(m, down) {
				padding-top: 30px;
    			padding-bottom: 40px;
				margin-left: 0;
			}
		}
		@include mq(l){
			margin-bottom: 0;
		}
		.description {
			max-width: 445px;
			width: 100%;
			font-weight: 400;
			margin: 17px 0 18px;
			font-size: 18px;
			line-height: 26px;
			font-family: $body-font-name;
		}

		.button-row {
			display: flex;
			flex-flow: column;
			flex-direction: row-reverse;
			justify-content: space-between;
			max-width: 375px;
			gap: 18px;

			a {
				width: 100%;
				flex-grow: 1;
			}
			
			@include mq(m) {
				a {
					width: auto;
					&:first-child {
						margin-right: 0;
					}
				}
			}
			.btn-blue{
				padding: 0.9em 0.8em;
				font-family: $header-font-name;
				font-size: 21px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px;
			}
			.adv-btn{
				font-family: $header-font-name;
				font-size: 21px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px;
				align-items: center;
				padding: 0.9em 0.2em;
			}
		}
	}
	#mc_embed_signup {
		form {
			margin: 0;
		}
		.asterisk {
			font-size: 100%;
			top: 4px;
		}
		.clear {
			margin-top: 1rem;
		}
	}

	.form-cell {
		width: 100%;
		max-width: 50%;
		background-position: center center;
		background-size: cover;
		padding: 115px 0px;

		@include mq(m, down){
			max-width: 100%;
		}

		.inner-wrap {
			width: 90%;
			padding-left: 20px;
			max-width: 450px;

			// @include mq(l){
			// 	max-width: 54%;
			// 	padding-left: 71px;
			// }
			// @include mq(m){
			// 	max-width: 85%;
			// 	padding-left: 48px;
			// }
		}
		.form-title {
			color: white;
			font-weight: 700;
		}

		.subscribe-wrap  {
			display: flex;
			margin-top: 1rem;

		}

		input[type="email"], input[type="text"] {
			border: unset;
			box-shadow: 3px 3px 8px rgba($gray, .3) !important;
			width: 100%;
			border-radius: 0;
			flex-grow: 1;
			font-size: 18px;
			margin-right: 0;
			font-weight: 600;
			color: rgba(0, 0, 0, 0.50);
			font-family: "Open Sans";
			outline: none;
		}

		input[type="submit"] {
			height: 65px;
			font-family: $header-font-name;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			padding: .9rem 1.2rem;
			box-shadow: 3px 3px 8px rgba($gray, .3);
			margin: 0;
			border: unset;
			border-radius: 0;
			background: $tertiary-color;
			transition: background-color .3s ease;
			color: white;
			font-weight: bold;

			&:hover, &:active, &:focus {
				background: $tertiary-color-light;
			}

			&:active {
				background: darken($tertiary-color, 5%);
			}
		}
	}
}

#care-awaits{
	background-color: $secondary-color;
	padding: 10px 0;
	.f-grid{
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
		align-items: center;
  		justify-content: center;
		@include mq(l){
			gap: 100px;
		}
		@include mq(m){
			grid-template-columns: 2fr 1fr;
			gap: 50px;
		}
		.info-cell{
			h2{
				color: #fff;
				font-size: 28px;
    			line-height: 36px;
				margin-bottom: 15px;
				@include mq(m){
					font-size: 37px;
					line-height: 46px;
				}
			}
			.description{
				color: #FFF;
				font-family: "Open Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
			}
		}
		.button-row{
			@include mq(m){
				margin-left: auto;
			}
			a{
				color: #FFF;
				padding: 1.01em 1.1em;
				font-family: $header-font-name;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 26px;
			}
		}
	}
}