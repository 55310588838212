//@import "../mixins/breakpoints";
#hero {
	background-position: 25% top;
	background-size: auto 65%;
	@include mq(m) {
		background-position: center center;
		background-size: cover;
	}
	display: flex;
	//background: $primary-color;
	.s-inner-wrap {
		padding-top: 20px;
		padding-bottom: 0;
		
		@include mq(m) {
			padding-top: 10px;
			padding-bottom: $padding-c-tb;
		}
	}

	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@include mq(m) {
			grid-template-columns: 1.2fr 0.8fr;
			grid-gap: 30px;
		}
	}

	.content-box {
		background: white;
		padding: 20px 15px;
		display: flex;
		flex-flow: column nowrap;

		.fancy-btn {
			align-self: flex-start;
		}

		@include mq(m) {
			background: rgba(white, 0.8);
			padding: 90px 60px 40px 0;
		}
		h1{
			font-size: 28px;
			padding-right: 0;
			line-height: 36px;
			color: $primary-color;
			font-family: $header-font-name;
			font-style: normal;
			font-weight: 700;
			@include mq(l){
				padding-right: 0;
				font-size: 52px;
				line-height: 66px;
			}
			@include mq(m){
				padding-right: 0;
				font-size: 32px;
        		line-height: 35px;
			}
		}
		p{
			margin-top: 0;
			color: #000;
			font-family: "Open Sans";
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			max-width: 475px;
			margin-bottom: 32px;
		}
		a{
			font-size: 21px;
			line-height: 28px;
			font-family: $header-font-name;
			padding: 0.9em 1.28em;
		}
	}
	.moons {
		position: relative;
		padding-bottom: 65%;
		width: 90%;
		margin: 1rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		@include mq(l) {
			height: 560px;
		}
		@include mq(m) {
			width: 100%;
			padding-bottom: 0;
			margin: 0;
			height: 410px;
		}
		.moon-icon {
			position: absolute;
			color: $tertiary-color;
			&:first-child {
				top: 40%;
				right: 64px;
				width: 20%;
				transform: rotate(10deg);
				@include mq(m){
					top: 42%;
					right: 12px;
					width: 20%;
				}
				svg{
					path{
						stroke-width: 3.7;
						@include mq(s, down){
							stroke-width: 3.3;
						}
					}
				}
			}

			&:nth-child(2) {
				top: -10px;
				left: 21%;
				width: 30%;
				transform: rotate(20deg);
				z-index: -1;
				color: $purple;
				@include mq(m){
					top: 12px;
					left: 5%;
					width: 42%;	
				}
				svg{
					path{
						stroke-width: 3.7;
						@include mq(s, down){
							stroke-width: 3.3;
						}
					}
				}
			}

			&:last-child {
				bottom: -10px;
				width: 50%;
				left: 21%;
				color: $primary-color;
				@include mq(xl){
					left: 0%;
					width: 85%;
					bottom: -25px;
				}
				@include mq(l){
					left: -4%;
					width: 90%;
					bottom: -10px;
				}
				@include mq(m){
					width: 87%;
    				left: -7px;
					bottom: -7px;
				}
				@include mq(l, down){
					svg{
						path{
							stroke-width: 5;
						}
					}
				}
				@include mq(567px, down){
					svg{
						path{
							stroke-width: 6;
						}
					}
				}
			}
		}
	}
}

/* New Program section*/
.programs-s{
	background-color: #2E368F;
	padding: 12px 0 78px;
	position: relative;
	@include mq(s, down){
		padding: 12px 0 52px;
	}
	.s-inner-wrap{
		@include mq(xl){
			padding-right: 0;
		}
	}
	.top{
		display: flex;
    	justify-content: space-between;
		align-items: center;
		@include breakpoint(sm){
			position: relative;
		}
		h2{
			color: #FFF;
			font-size: 26px;
			font-style: normal;
			font-weight: 700;
			line-height: 34px;
			margin-bottom: 0;
			@include mq(m){
				font-size: 37px;
				line-height: 46px;
			}
		}
		.all-services{
			display: none;
			a{
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 22px;
				padding: 18px 20px;
				text-transform: capitalize;
				@include mq(m){
					font-size: 21px;
					line-height: 28px;
				}
			}
			@include mq(s){
				display: flex;
			}
		}
	}
	.all-services.mb{
			display: flex;
			justify-content: flex-start;
			margin: 40px 30px 0;
		a{
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 22px;
			padding: 18px 20px;
			text-transform: capitalize;
			@include mq(m){
				font-size: 21px;
				line-height: 28px;
			}
		}
		@include mq(s){
			display: none;
		}
	}
	.programs-carousel {
		&::after{
			content: "";
			position: absolute;
			width: 22%;
			height: calc(100% + 0px);
			top: 50%; 
			transform: translateY(-50%) scale(-1);
			right: 0;
			z-index: 2;
			background: linear-gradient(92deg, #2E3192 1.45%, rgba(46, 49, 146, 0.00) 82.74%);
		}

		&::before {
			content: "";
			position: absolute;
			width: 22%;
			height: calc(100% + 0px);
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			background: linear-gradient(92deg, #2E3192 1.45%, rgba(46, 49, 146, 0.00) 82.74%);
			z-index: 2;
			
		}
		.slick-arrow{
			position: absolute;
			top: 50%;
			z-index: 3;
			cursor: pointer;
			&:hover{
				svg{
					path{
						stroke: #B14FC5;
						transition: all 0.3s ease;
					}
				}
			}
		}

		.slick-prev{
			left: 1%;
			transform: translate(50%, -50%);
			@include mq(l){
				left: 7%;
			}
			@include mq(m){
				left: 4%;
			}
		}
		.slick-next{
			right: 1%;
			transform: translate(-50%, -50%);
			@include mq(l){
				right: 7%;
			}
			@include mq(m){
				right: 4%;
			}
		}
		.slick-dots{
			width: fit-content;
			display: flex;
			margin: 30px auto 0;
			padding: 0;
		}

		.slick-slide {
			position: relative;
			padding: 0 10px;
			height: auto;
			width: 360px;
			transition: all 0.5s ease;

			img {
				height: 225px;
				object-fit: cover;
				width: 100%;
			}

			.title {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				color: #fff;
				font-family: "Open Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px;
				z-index: 1;
				@include mq(l){
					font-size: 31px;
					line-height: 38px;
				}
				@include mq(m){
					font-size: 26px;
					line-height: 34px;
					width: 80%;
				}
			}
		}
		.slick-slide{
			a {
				display: flex;
				position: relative;
				&::after{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 0;
				}
			}
		}
		.slick-slide:nth-child(3n+1){
			a{
				&::after{
					background-color: #2e368f87 !important;
				}
				&:hover{
					&:after{
						background-color: #2e368fbd !important;
					}
				}
			}
		}
		.slick-slide:nth-child(3n+2){
			a{
				&::after{
					background-color: #b14fc5b5 !important;
				}
				&:hover{
					&:after{
						background-color: #b14fc5d6 !important;
					}
				}
			}
		}
		.slick-slide:nth-child(3n+3){
			a{
				&::after{
					background-color: #8dc73f91 !important;
				}
				&:hover{
					&:after{
						background-color: #8dc73f6e !important;
					}
				}
			}
		}
	}
}

#quality-care {
	position: relative;
	
	.overlay {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 1;
		background:linear-gradient(90deg, #fff 60%, #ed7f2800 100%);

		@include mq(m){
			background: linear-gradient(90deg, #fff 45%, #ed7f2800 70%);
		}
	}
	&::after{
		content: "";
		position: absolute;
		width: 10%;
		height: calc(100% + 0px);
		top: 50%;
		transform: translateY(-50%) scale(-1);
		right: 0;
		z-index: 2;
		background: linear-gradient(88deg, #FFF 12.01%, rgba(255, 255, 255, 0) 85.14%);;
		@include mq(m){
			background: linear-gradient(82deg, #FFF 23.01%, rgba(255, 255, 255, 0.00) 77.14%);
		}
	}

	&::before {
		content: "";
		position: absolute;
		width: 4%;
		height: calc(100% + 0px);
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background: radial-gradient(ellipse 50% 200% at 54%, #ffffff00 64%, #ffffff 93%);
		z-index: 2;
		
		@include mq(m){
			width: 10%;
		}
	}

	.s-inner-wrap {
		padding: 40px 20px 60px 40px;
		position: relative;
    	overflow: visible;
		z-index: 3;

		@include mq(m) {
			padding: 120px 20px 152px;
		}

		.icon-right, .icon-left {
			position: absolute;
			color: #EBEBEB;
		}

		.icon-left {
			width: 192px;
			bottom: 4%;
			left: 0;

			@include mq(m) {
				left: -6%;
			}
		}

		.icon-right {
			width: 81px;
			display: none;
			right: 0;
			top: 46px;	
			z-index: 3;

			@include mq(m) {
				right: -3%;
				display: initial;
			}
		}		
	}
	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;
		width: 500px;
		margin: initial;

		@include mq(m) {
			margin: 0 auto;
		}

		.cell {
			display: flex;
		}

		@include mq(m) {
			width: 100%;
			grid-gap: 5em;
			grid-template-columns: 1fr 1fr;

			.cell:last-child {
				grid-column: 2;
			}
		}

		@include mq(l) {
			grid-gap: 40px;
		}

		.cell:last-child {
			grid-row: 1;
		}

		.right-col {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 55%;
			@include mq(700px, down){
				display: none;
			}
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 102%;
				top: 0;
				z-index: 0;
				background: linear-gradient(90deg, #ffffff 20%, #ffffff00, #ffffffff);
			}
		}
	}

	.image-box {
		position: relative;

		.moons {
			position: absolute;
			top: 0;
			left: 0;
			width: 80%;
			height: 100%;
			padding: 1rem;

			.moon-icon {
				position: absolute;
				color: $secondary-color;
				&:first-child {
					top: 60%;
					right: 0;
					width: 25%;
				}

				&:nth-child(2) {
					top: 0;
					left: 5%;
					width: 35%;
				}

				&:last-child {
					bottom: 1rem;
					width: 45%;
				}
			}
		}
	}

	.info-box {
		margin: auto 0;
		z-index: 1;

		h2 {
			@include mq(l) {
				padding-right: 70px;
			}
		}

		.quality-desc {
			margin: 11px 0 20px;
			line-height: 26px;
			font-family: $body-font-name;
			color: #000;
		}

		a {
			text-transform: capitalize;
			font-size: 21px;
			font-family: $header-font-name;
			line-height: 30px;
			padding: 18px 38px;
		}
	}
}

.wpsl_stores_home_main{
	display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;
	margin: 17px 0 20px;
	@include mq(m){
		flex-direction: row-reverse;
	}
	#wpsl-gmap{
		margin-top: 30px;
		@include mq(m){
			height: 460px !important;
			margin-top: 70px;
		}
	}
	
	#wpsl_stores_home{
		grid-template-columns: 1fr !important;
		gap: 0 !important;
		overflow: hidden;
        padding-bottom: 80px;

		.slick-arrow{
			position: absolute;
			bottom: 0;
			z-index: 10;
			border: 1px solid #8DC73F;
			padding: 17px 27px;
			cursor: pointer;
			transition: all .5s ease;
			svg{
				transition: all .3s ease;
				path{
					fill: #8DC73F;
				}
			}
			&:hover{
				border: 1px solid #B14FC5;
    			background-color: #B14FC5;
				svg{
					path{
						fill: #fff;
					}
				}
			}
		}
		.slick-prev{
			right: 80px;	
		}
		.slick-next{
			right: 0;
		}
	}
	#wpsl-result-list{
		margin: 0 !important;
		position: relative;
		.wpsl-search{
			margin-bottom: 36px !important;
		}
		#wpsl-search-wrap{
			padding-top: 0;
			.loc-top{
				grid-template-columns: 1fr;
				width: 100%;
				.loc-main-title{
					h2{
						font-size: 37px;
						font-style: normal;
						font-weight: 700;
						line-height: 46px;
						margin-bottom: 25px;
					}
				}
				.input-search-wrap-outer{
					max-width: 100%;
				}
			}
		}
		.slick-list{
			margin: 0 -10px;
			.slick-slide{
				margin: 0 10px;
			}
		}
		.viewLocations{
			max-width: 56%;
			position: absolute;
			bottom: 0;
			width: 100%;
			@include mq(xl){
				max-width: 67%;
			}
			@include mq(l){
				max-width: 60%;
			}
			@include mq(m){
				max-width: 57%;
			}
			
			a{
				width: 100%;
    			padding: 19px 20px;
				font-family: $header-font-name;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: 27px;
				margin-bottom: 0;
				@include mq(l){
					font-size: 21px;
				}
				@include mq(m){
					font-size: 17px;
				}
				@include mq(s){
					font-size: 20px;
				}
			}
		}
	}
}

#locations {
	background-position: 25% top;
	background-size: auto 65%;
	@include mq(m) {
		background-position: center center;
		background-size: cover;
	}
	display: flex;
	.s-inner-wrap {
		padding-top: 20px;
		padding-bottom: 0;

		@include mq(m) {
			padding-top: $padding-c-tb;
			padding-bottom: $padding-c-tb;
		}
	}
	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;

		@include mq(m) {
			grid-template-columns: 1fr;
			grid-gap: 30px;
		}
	}

	.moons {
		position: relative;
		padding-bottom: 65%;
		margin: 1rem;
		width: 75%;

		@include mq(m) {
			width: 100%;
			padding-bottom: 85%;
			margin: 0;
		}
		.moon-icon {
			position: absolute;
			color: $primary-color;
			&:first-child {
				top: 30%;
				right: 0;
				width: 25%;
			}
			&:nth-child(2) {
				top: 0;
				left: 5%;
				width: 35%;
			}

			&:last-child {
				bottom: 0;
				width: 45%;
			}
		}
	}

	.inner-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;

		@include mq(l) {
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
		}
		.cell{
			h4{
				@include mq(l) {
					font-size: 37px;
					font-style: normal;
					font-weight: 700;
					line-height: 46px;
					max-width: 75%;
				}
				
			}
		}
	}

	.location-box {
		background: $off-white;
		padding: 30px;
		.location-description {
			font-size: var(--fluid-body-sm);
			color: $gray;
			margin-bottom: 0.5rem;
		}
		@include mq(m) {
			padding: 48px 11px 38px 35px;
		}
	}
}

#home-news {
	padding: 30px 0 67px;
	.top{
		display: flex;
    	justify-content: space-between;
		align-items: center;
		h2{
			font-size: 24px;
			line-height: 32px;
			font-style: normal;
			font-weight: 700;
			@include mq(m){
				font-size: 37px;
				line-height: 46px;
			}
		}
		.all-news{
			a{
				font-size: 16px;
				line-height: 24px;
				font-style: normal;
				font-weight: 700;
				padding: 18px 34px;
				text-transform: capitalize;
				@include mq(m){
					font-size: 21px;
					line-height: 28px;
				}
			}
		}
	}
	.news-grid {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr;
		margin-top: 28px;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}

		@include mq(l) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

.c-grid,
.f-grid {
	max-width: 100%;
}