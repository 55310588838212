.county-card {
	background: $off-white;
	padding: 30px;
	width: 1200px;
	max-width: 100%;
	margin: 0 auto;
	margin-top: 79px;
    margin-bottom: 59px;


	@include mq(s, down) {
		width: 100vw;
		max-width: 100vw;
		margin-left: calc(50% - 50vw);
	}
	@include mq(m) {
		padding: 48px 11px 38px 35px;
	}

	.inner-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;

		@include mq(l) {
			grid-template-columns: 1.1fr 0.9fr;
			grid-gap: 30px;
		}
		h4{
			@include mq(l){
				font-size: 37px;
				font-style: normal;
				font-weight: 700;
				line-height: 46px;
				max-width: 432px;
			}
		}
	}

	.pa-image {
		padding: 0;
	}
}

.county-list {
	list-style: none;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 5px;
	padding-left: 0;
	a {
		display: flex;
		justify-content: flex-start;
		color: $gray;
		font-size: var(--fluid-body-sm);
		.pin-icon {
			display: block;
			width: 1em;
			height: 1em;
			margin-right: .4em;
			color: $secondary-color;

			svg {
				height: 100%;

				g {
					fill: transparent;
					transition: all .3s ease;
				}
			}
		}

		&:hover, &:active, &:focus, &[data-s-active-anchor] {
			.pin-icon {
				svg {
					g {
						fill: $primary-color;
					}
				}
			}
		}
	}
}
