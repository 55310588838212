#programs-archive {
	.f-grid {
		display: flex;
		flex-flow: column-reverse nowrap;
		justify-content: center;
		align-items: center;
		gap: 20px;
		column-gap: 20px;

		@include mq(l) {
			flex-direction: row;
			column-gap: 80px;
			align-items: flex-start;
			flex-flow: row nowrap;
		}
	}
}

.program-list-wrapper {
	max-width: 100%;
}

.program-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	width: 350px;
	max-width: 100%;
	list-style: none;
	padding-left: 0;
	margin-top: 0;
	.program-box {
		display: flex;
		flex-flow: column nowrap;
		border: 3px solid currentColor;
		margin-top: 30px;
		color: $secondary-color;
		text-align: center;
		padding: 10px;
		transition: color 0.5s ease;
		height: calc(100% - 30px);

		.program-icon {
			margin-top: -40px;
			align-self: center;

			background: white;
			padding: 5px;
			svg {
				height: 75px;
				transition: transform 0.5s ease;
				path {
					stroke: $secondary-color;
				}
			}
		}

		div {
			margin-top: 5px;
			color: $secondary-color;
			font-size: var(--fluid-body-sm);
		}

		&:hover,
		&:focus {
			color: lighten($primary-color, 15%);

			svg {
				transform: scale(1.08);
			}
		}

		&:active {
			color: $primary-color;
		}
	}
}
