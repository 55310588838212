.search-results {
	h2.entry-title {
		font-family: $body-font-family;
	}
	p {
		font-family: $body-font-family;
	}
	.card-link {
		display: flex;
		justify-content: flex-end;
		color: $secondary-color;
		margin-left: auto;
		font-family: $body-font-family;
		font-size: 15px;
		.arrow-icon {
			margin-left: 0.5em;

			svg {
				height: 0.8em;
				transition: transform 0.3s ease, color 0.3s ease;
				transform: translateX(0);
			}
		}

		&:hover,
		&:active,
		&:focus {
			.arrow-icon {
				svg {
					transform: translateX(10px);
					color: $primary-color;
				}
			}
		}

		&:active {
			color: $primary-color;
		}
	}
}
