.mm-active{
	overflow: hidden !important;
}
.header-nav {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	box-shadow: 1px 1px 0px #EBEBEB;
	z-index: 100;
	background-color: #fff;

	.header-row {
		display: flex;
		flex-flow: column nowrap;
		.menu-row{
			padding-bottom: 0 !important;
		}
		.header-row-inner-wrap {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			width: 100%;
			max-width: $global-width;
			margin: 0 auto;
			padding: 10px 0 0 20px;
			align-items: center;
			.header-cell{
				.logo-container{
					margin-bottom: 15px;
					display: flex;
				}
			}
			.cell-main-menu{
				z-index: 1;
			}
		}

		.header-link {
			display: flex;
			align-items: center;
			color: $secondary-color;
			border: unset;
			background: unset;
			font-family: $body-font-family;
			font-size: var(--fluid-body-sm);
			font-weight: 700;

			@include breakpoint(400px, down) {
				font-size: 11px;
			}

			.icon {
				display: flex;
				align-items: center;
				margin-right: 0.3em;

				svg {
					height: 1em;
				}
			}
		}
	}

	.top-row {
		background: $off-white;
		.header-row-inner-wrap {
			padding: 0 0 0 10px;
			justify-content: space-between;

			.header-link {
				padding: 12px 0;
				margin-left: 0.4em;
				transition: color 0.3s ease;
				display: flex;
				flex-flow: column nowrap;

				@include breakpoint(510px, up) {
					flex-flow: row nowrap;
					margin-left: 1em;
				}

				@include mq(m) {
					margin-left: 2em;
				}

				&:first-child {
					margin-left: 0;
				}

				&:hover,
				&:active,
				&:focus {
					color: $primary-color;
				}
			}
			button.header-link {
				cursor: pointer;
			}

			.callout-link {
				background-color: $primary-color;
				padding: 13px 14px 11px;
				color: white;
				transition: background 0.3s ease;

				.icon {
					color: $secondary-color;
				}

				&:hover,
				&:active,
				&:focus {
					background-color: $primary-color-light;
					color: white !important;
				}
			}
			button {
				padding: 0;
			}

			@include mq(m) {
				justify-content: flex-end;
			}
		}
	}
}

// Better menu mods

#desktop-nav {
	//display: none;

	@include mq(l) {
		display: flex;
	}
	#menu-main-navigation{
		gap: 23px;
	}
	.menu-item {
		font-size: .9em;
		a{
			padding: 0;
		}
		&.menu-location-btn{
			a{
				color: #fff;
				padding: 17px 28px;
				font-family: 'Poppins';
				font-size: 18px;
				line-height: 24px;
				&:hover{
					color: $purple;
				}
			}
		}
	}	
}

#mobile-nav-button {
	display: flex;

	@include mq(l) {
		display: none;
	}
}

#navigation-modal {
	a {
		color: white;

		&:hover,
		&:focus {
			color: $secondary-color;
		}

		&:active {
			color: $secondary-color-dark;
		}
	}
	[data-bm-card] {
		width: 100%;
		background: $primary-color;

		.bm-card-inner {
			padding: 2em;
		}

		[data-bm-button] {
			color: white;
			margin-left: auto;
			padding: 1rem;
			font-weight: 500;
			cursor: pointer;

			svg {
				padding: 0.4rem 0.2rem;
			}
		}
	}

	.nav-donate-btn {
		display: flex;
		align-items: center;
		margin-top: 2rem;

		svg {
			height: 1em;
			margin-right: 0.4rem;
		}
	}
}

// make sub menu items abit less bold
#mobile-nav {
	li {
		> ul {
			padding-top: 0;
			a {
				font-weight: 500;
			}
		}
	}
}

.menu-row {
	padding-top: 0.8em;
}

.bm-card-inner {
	max-width: 100%;
	margin: 0 auto;
	width: 350px;
}

.default-modal {
	background: $primary-color;
	width: 100%;
	max-width: 100vw;
	.bm-card-inner {
		padding: 4rem 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mq(m) {
			padding: 4rem 2rem;
		}
	}

	[data-bm-button] {
		color: white;
		margin-left: auto;
		margin-right: 1rem;
		margin-top: 1rem;
		padding: 1rem;
		font-weight: 500;
		cursor: pointer;

		svg {
			padding: 0.4rem 0.2rem;
		}
	}
}

#search-modal {
	[data-bm-card] {
		background: $secondary-color;
	}
}

#searchform {
	display: flex;
	max-width: 100%;
}
.search-wrap {
	max-width: 100%;
	.search-title {
		padding-bottom: 1rem;
		color: white;
		font-weight: 400;
	}

	.input-group {
		display: flex;
		width: 500px;
		max-width: 100%;

		input[type="text"] {
			border: unset;
			box-shadow: 3px 3px 8px rgba($gray, 0.3);
			//height: 2.5em;
			width: auto;
			border-radius: 0;
			flex-grow: 1;
			font-size: var(--fluid-body-sm);
			padding: 15px;
		}

		input[type="submit"] {
			font-size: var(--fluid-body-sm);
			padding: 15px 40px;
			align-self: flex-start;
			box-shadow: 3px 3px 8px rgba($gray, 0.3);
			border: unset;
			border-radius: 0;
			background: $tertiary-color;
			color: white;
			font-weight: bold;
		}
	}
}
#desktop-nav{
	.ubermenu-responsive-toggle{
		padding: 0 10px 10px;
	}
}
// Ubermenu
.ubermenu {
	.ubermenu-target-text {
		font-size: 17px;
	}
}

.ubermenu-nav{
	.ubermenu-item.arrow-cs{
	> a{
		padding-right: 30px !important;
	}
	}
	.ubermenu-item.items{
		> a{
			padding: 10px 9px 38px;
		}
	}
	.ubermenu-submenu {
		.col-left-img{
			margin: 27px 0 35px 20px;
			@include mq(l){
				margin: 27px 0 35px 0;
			}
		}
		.menu-pr{
			margin: 25px 0 20px;
			@include mq(l){
				margin: 55px 20px 20px;
			}
		}
		.menu-pr.left{
			margin-left: 0;
			@include mq(l){
				margin-left: 55px;
			}
		}
	}
	li.arrow-cs{
		[data-bm-dropdown-arrow]{
			display: none !important;
		}
		> .ubermenu-target{
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;

			@include mq(l){
				flex-direction: row-reverse;
			}
			&::after{
				content: "";
				background-image: url("../images/arrow-icon.svg");
				width: 17px;
				height: 10px;
				right: 4px;
				position: absolute;
				background-repeat: no-repeat;
				transition: all 0.3s ease;
				margin-top: 3px;
				background-size: 100%;
			}
			&:hover{
				&::after{
					transform: rotate(180deg);
				}
			}
			.ubermenu-sub-indicator-close{
				top: 10px;
			}
		} 
	}
	.ubermenu-submenu.ubermenu-submenu-drop {
		border: 0 !important;

		.ubermenu-row {
			padding: 0 20px;
			@include mq(l, down) {
				display: flex;
    			flex-direction: column;
			}
		}
		
		.ubermenu-submenu-type-stack{
			transform: none;
			padding: 0;
			.programs-left-image{
				padding: 0 !important; 
				img{
					max-width: 382px;
				}
			}
			li{
				margin: 0 !important;
				padding: 0 !important;
				a{
					color: #fff;
					@include mq(m){
						font-size: 16px;
						padding-bottom: 8px;
					}
					&:hover{
						span{
							color: #2e3192;
							transition: all .2s ease;
						}
					}
				}
			}
		}
		&::before{
			content: "";
			position: absolute;
			background-image: url("../images/submenu-bg.png");
			width: 100%;
			height: 100%;
			right: 150px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: 100% 100%;
			@include mq(m, down){
				display: none;
			}
		}
		
	}
	li:last-child{
		margin-left: 15px;
		margin-bottom: 23px;
	}
}
.ubermenu-mobile-view{
	@include mq(l, down){
		top: 134px !important;
		border-top: 1px solid #eee !important;
	}
	.ubermenu-nav{
		padding: 0 20px;
		.ubermenu-item-level-0{
			@include mq(l, down){
				width: 100%;
			}
		}
		.menu-pr{
			margin: 27px 0 0;
			&:last-child{
				margin: 0 !important;
			}
		}
		.ubermenu-row{
			.col-left-img{
				order: 1;
				@include mq(m, down){
					margin: 27px 0 25px 0 !important;
					display: flex;
					justify-content: center;
				}
			}
		}
		.ubermenu-active{
			> a.ubermenu-target{
				&::after{
					transform: rotate(180deg);
				}
			}
		}
	}
	.ubermenu-mobile-footer{
		display: none !important;
	}
	.ubermenu-sub-indicator-close{
		.fa-times{
			display: none !important;
		}
	}
}
.admin-bar{
	.ubermenu-mobile-view{
		@include mq(l, down){
			top: 185px !important;
		}
	}
}