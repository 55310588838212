.single-event {
	background-color: #fff;
	position: relative;
    padding-top: 60px;
	.f-grid {
		display: flex;
		flex-flow: wrap-reverse;
		align-items: flex-start;
		gap: 30px;
		// padding: 0 2em;
		@include mq(l) {
			flex-flow: row nowrap;
			padding: 0;
		}
		.sidebar-wrapper {
			width: 380px;
			padding: 1.2em;
			padding-top: 0;
			background-color: transparent;
			border-left: 1px solid $off-white;
			@media all and (max-width: 1022px) {
				display: none;
			}
		}
	}
	.event-content {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		margin-left: 0;
	}

	.essential-details-box {
		// background-color: $off-white;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
		border-bottom: 1px solid $off-white;
		gap: 30px;
		padding: 1em;
		.detail-item {
			.detail-field {
				font-size: 16px;
				margin: 0;
				font-weight: bold;
			}
		}
	}
	.external-link {
		display: inline-block;
		background-color: $off-white;
		padding: 1em;
	}
	.single-event-title {
		font-family: $body-font-family;
		color: $primary-color;
		width: 100%;
		border-bottom: 1px solid $off-white;
		padding-bottom: 0.35em;
	}
	.eyebrow {
		font-size: 14px;
		font-weight: medium;
		margin-bottom: 0;
		margin-top: 0;
	}
	.date-tag {
		font-family: $body-font-family;
		color: $gray;
		width: 100%;
	}
	.venue-tag {
		font-family: $body-font-family;
		color: $secondary-color;
		font-weight: bold;
		font-size: var(--fluid-h5);
	}
	#event-breadcrumbs {
		margin-top: 0;
	}
	.event-featured-image {
		width: 100%;
		height: 350px;
		object-fit: cover;
		margin-top: 0;
		margin-bottom: 1rem;
		align-self: center;
		@media all and (max-width: 768px) {
			height: 200px;
		}
	}
	.additional-events {
		display: flex;
		flex-flow: row wrap;
		margin-top: 2rem;
		align-items: center;
		justify-content: center;
		width: 100%;

		.cell {
			padding: 0.5rem;
			flex-basis: 100%;

			@include mq(m) {
				flex-basis: 50%;
			}

			@include mq(l) {
				flex-basis: 33.33%;
			}
		}
		h4 {
			width: 100%;
			margin-bottom: 1rem;
			text-align: center;
			color: $black;
		}
	}
	.content {
		border-bottom: 1px solid $off-white;
		padding: 1em;
		width: 100%;
	}
}

.sidebar-wrapper {
	.c-grid {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		&.row-layout {
			grid-template-columns: 1fr;
			.event-card {
				background: white;
				&.row {
					flex-wrap: wrap;
				}
				.image {
					width: 100%;
					background: none !important;
				}

			}
		}
	}
}
